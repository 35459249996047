<template>
  <van-nav-bar
    :title="title"
    :left-text="ShowBack ? $t('lang.back') : ''"
    :left-arrow="ShowBack"
    fixed
    placeholder
    @click-left="onBack"
    @click-right="onClickRight"
  >
    <template #right>
      <van-icon :name="RightIcon" size="18" />
    </template>
  </van-nav-bar>
</template>
<style scoped>
/deep/ .van-nav-bar {
  color: #fff;
  background-color: #336666;
}
/deep/ .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #fff;
  font-weight: 500;
  font-size: 17px;
}
/deep/ .van-nav-bar__text {
  color: #fff;
}
/deep/ .van-icon {
  color: #fff;
}
</style>
<script>
export default {
  props: {
    title: String,
    ShowBack: Boolean,
    BackUrl: String,
    RightIcon: String,
  },
  methods: {
    onBack() {
      if (this.BackUrl == undefined || this.BackUrl == "") {
        this.$router.back();
      } else this.$router.replace(this.BackUrl);
    },
    onClickRight() {
      if (this.RightIcon && this.RightIcon != "") this.$emit("rightclick");
    },
  },
};
</script>