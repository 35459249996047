<template>
  <div>
    <MyHeader :title="$t('lang.menu_myrouter')" :ShowBack="true" />
    <van-cell-group
      v-for="mDept in lstDepartment"
      :key="mDept.id"
      :title="mDept.name"
    >
      <van-cell
        v-for="mData in getLineList(mDept.id)"
        :key="mData.id"
        :title="mData.name"
        :value="mData.r"
      />
    </van-cell-group>
  </div>
</template>
<script>
import MyHeader from "@/components/Header.vue";
export default {
  components: { MyHeader },
  data() {
    return {
      lstDepartment: this.$store.state.depts,
      lstLine: this.$store.state.routes   
    };
  },
  methods:{
    getLineList(deptid){
      return this.lstLine.filter((d) => {
        return d.deptId == deptid;
      });
    }
  }
};
</script>
